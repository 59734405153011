import {useEffect, useState} from 'react';
import Input from 'shared-web/components/Input.js';
import {formatDate, formatIban} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useForm} from 'react-hook-form';
import {setFormErrors} from 'shared-web/effects.js';
import Button from 'shared-web/components/Button.js';
import IconButton from 'shared-web/components/IconButton.js';
import {RPC} from 'shared/api.js';

import {useResource} from '../../hooks.js';
import DepositAccountReferenceField from '../../components/reference_field/DepositAccountReferenceField.js';
import DepositReferenceField from '../../components/reference_field/DepositReferenceField.js';
import {alert, handleError} from '../../effects.js';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';

import styles from './DepositAccountVibanDialog.module.scss';

export default function DepositAccountVibanDialog({...props}) {
  const {id} = useParams();
  const [deposit_account_viban] = useResource('listDepositAccountVibans', id);
  const [readOnly, setReadOnly] = useState(true);
  const [visible, setVisible] = useState(true);

  const {
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(deposit_account_viban);
  }, [reset, deposit_account_viban]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      deposit_account_viban,
      setError,
      setVisible,
      setReadOnly,
    });
  });

  const Footer = (
    <div className={styles.footer}>
      <div>
        <div className={styles.save_button_wrapper}>
          {!readOnly && (
            <Button
              title="Save"
              loading={isSubmitting}
              onClick={onSubmit}
              disabled={!isDirty}
            />
          )}
        </div>
      </div>
    </div>
  );

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Deposit Account Viban"
      footer={Footer}
      show={visible}
      additionalIcons={[readOnly && <EditIcon />]}
      {...props}>
      <Input value={deposit_account_viban.id} label="ID" readOnly />
      <Input
        value={formatIban(deposit_account_viban.viban)}
        label="Viban"
        readOnly
      />
      <Input
        label="Onboarding External Reference"
        {...register('onboarding_external_reference')}
        readOnly={readOnly}
        error={errors.onboarding_external_reference?.message}
      />
      <DepositAccountReferenceField
        id={deposit_account_viban.deposit_account_id}
        label="Deposit account"
      />
      <DepositReferenceField
        id={deposit_account_viban?.deposit_id}
        label="Deposit"
      />
      <Input
        value={formatDate(deposit_account_viban.created_at)}
        label="Created at"
        readOnly
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  deposit_account_viban,
  setError,
  setVisible,
  setReadOnly,
}) {
  try {
    await RPC('updateDepositAccountViban', {
      id: deposit_account_viban.id,
      onboarding_external_reference: fields.onboarding_external_reference,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  setVisible(false);
  setReadOnly(true);
}
