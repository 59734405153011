import React from 'react';
import {_} from 'shared/l10n.js';

import Button from '../Button.js';

import HintModal from './HintModal.js';
import styles from './ConfirmModal.module.scss';

export default function ConfirmModal({
  proceed_label,
  abort_label = _('Abbrechen'),
  onSubmit,
  onHide,
  ...props
}) {
  function proceed() {
    onHide();
    onSubmit(true);
  }

  function cancel() {
    onHide();
    onSubmit(false);
  }

  return (
    <HintModal onHide={cancel} {...props}>
      {(() => {
        return (
          <div className={styles.container}>
            <div className={styles.button}>
              <Button title={proceed_label} onClick={proceed} />
            </div>
            <div className={styles.secondary_button}>
              <Button title={abort_label} onClick={cancel} secondary={true} />
            </div>
          </div>
        );
      })()}
    </HintModal>
  );
}
