import {Link} from 'react-router-dom';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Placeholder from 'shared-web/components/combobox/Placeholder.js';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Combobox from './Combobox.js';

export default function SelectManager({
  name,
  value,
  onChange,
  selectedItemListener,
  error,
  label,
  readOnly,
  placeholder = '…',
  rpc_method,
}) {
  return (
    <Combobox
      name={name}
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      placeholder={placeholder}
      rpc_method={rpc_method}
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      error={error}
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
      label={label}
      readOnly={readOnly}
    />
  );
}

function DropdownItem({item}) {
  return <p className={text_styles.body1_bold_left}>{item?.display_name}</p>;
}

function itemToString(item) {
  return item?.id ? `${item.display_name}` : '';
}

function NoResultsPlaceholder() {
  return (
    <Placeholder>
      <p>There are no managers.</p>
      <p>
        You can create one{' '}
        <Link to={`${BACKOFFICE_PATHS.managers}/new`}>here</Link>.
      </p>
    </Placeholder>
  );
}

function NoSearchResultsPlaceholder({search_query}) {
  return (
    <Placeholder>
      <p>We could not find a manager with query „{search_query}“.</p>
      <p>
        You can create one{' '}
        <Link to={`${BACKOFFICE_PATHS.managers}/new`}>here</Link>.
      </p>
    </Placeholder>
  );
}
