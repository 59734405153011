import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import Icon from 'shared-web/components/Icon.js';

import {ReactComponent as ArrowUpSvg} from './icon-arrow-up.svg';
import {ReactComponent as ArrowDownSvg} from './icon-arrow-down-active.svg';
import {ReactComponent as ArrowDownInactiveSvg} from './icon-arrow-down-inactive.svg';
import styles from './TableHeaderButton.module.scss';

export default function TableHeaderButton({
  orderBy,
  column,
  sort_column,
  sort_direction,
}) {
  return (
    <button
      onClick={() => orderBy(column.sort)}
      className={classNames(text_styles.body1_bold_left, styles.header)}>
      {column.render('Header')}
      <Icon>
        {sort_column === column.sort ? (
          sort_direction === 'DESC' ? (
            <ArrowDownSvg />
          ) : (
            <ArrowUpSvg />
          )
        ) : (
          <ArrowDownInactiveSvg />
        )}
      </Icon>
    </button>
  );
}
