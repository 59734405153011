import React from 'react';
import ReactDOM from 'react-dom';
import 'shared-web/fonts.css';
// Import global scoped styles before App.js
// so that component css files and modules have priority
// and override them
import 'shared-web/bootstrap.scss';
import './index.scss';

import App from './App.js';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
