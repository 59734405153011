import Input from 'shared-web/components/Input.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import TextArea from 'shared-web/components/TextArea.js';

import {useResource} from '../../hooks.js';

import styles from './CovivioEventDialog.module.scss';

export default function CovivioEventDialog({...props}) {
  const {id} = useParams();
  const [covivio_event] = useResource('listCovivioEvents', id);

  return (
    <Dialog title="Covivio Event" className={styles.dialog} {...props}>
      <Input value={covivio_event.id} label="Id" readOnly />

      <Input
        value={new Date(covivio_event.created_at).toLocaleString()}
        label="Created at"
        readOnly
      />
      <TextArea value={covivio_event.payload} label="Payload" readOnly />
    </Dialog>
  );
}
