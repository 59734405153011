import Input from 'shared-web/components/Input.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';

import {JsonField} from '../../components/JsonField.js';
import {useResource} from '../../hooks.js';

import styles from './VvrbEventDialog.module.scss';

export default function VvrbEventDialog({...props}) {
  const {id} = useParams();
  const [vvrb_event] = useResource('listVvrbEvents', id);

  return (
    <Dialog title="Vvrb Event" className={styles.dialog} {...props}>
      <Input value={vvrb_event.id} label="Id" readOnly />

      <Input value={vvrb_event.type} label="Type" readOnly />

      <Input
        value={new Date(vvrb_event.created_at).toLocaleString()}
        label="Created at"
        readOnly
      />
      <JsonField
        collapsed={false}
        value={vvrb_event.payload}
        name="payload"
        label="Payload"
      />
    </Dialog>
  );
}
