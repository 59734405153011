import React, {useState} from 'react';
import Button from 'shared-web/components/Button';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {reviewDepositGuaranteeApplication} from '../../actions.js';

export default function ReviewDepositGuaranteeApplicationButton({
  deposit_guarantee_application,
}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  const {
    id: deposit_guarantee_application_id,
    review_required,
    tenant_application_submitted_at,
    accepted_at,
    rejected_at,
  } = deposit_guarantee_application;

  async function onClick() {
    setLoading(true);
    try {
      await reviewDepositGuaranteeApplication({
        deposit_guarantee_application_id,
      });
    } catch (err) {
      notify({text: err.message || err.code.toString()});
      return;
    } finally {
      setLoading(false);
    }

    notify({text: 'Deposit guarantee application has been reviewed'});
  }

  if (!review_required) return null;
  if (!tenant_application_submitted_at) return null;
  if (accepted_at) return null;
  if (rejected_at) return null;

  return (
    <Button title="Mark as reviewed" onClick={onClick} disabled={loading} />
  );
}
