import React, {forwardRef} from 'react';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './TextInput.module.scss';

function TextInput({className, ...props}, ref) {
  return (
    <input
      className={classNames(text_styles.body2_left, styles.input, className)}
      ref={ref}
      {...props}
    />
  );
}

export default forwardRef(TextInput);
