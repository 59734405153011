import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';
import DateInput from 'shared-web/components/DateInput.js';
import SeparatorLine from 'shared-web/components/SeparatorLine';
import {formatPrice, formatIban} from 'shared/utils';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import IconButton from 'shared-web/components/IconButton.js';
import {setFormErrors} from 'shared-web/effects.js';
import Button from 'shared-web/components/Button.js';
import {PROVIDERS} from 'shared/constants.js';
import Select from 'shared-web/components/Select.js';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import DepositReferenceField from '../../components/reference_field/DepositReferenceField.js';
import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import {DataGridField} from '../../components/data_grid_field/DataGridField.js';
import {JsonField} from '../../components/JsonField.js';
import {useResource} from '../../hooks.js';
import PropertyOwnerReferenceField from '../../components/reference_field/PropertyOwnerReferenceField.js';
import TenantPromotionCampaignReferenceField from '../../components/reference_field/TenantPromotionCampaignReferenceField.js';
import {updateDepositGuaranteeApplication} from '../../actions.js';
import SelectTenantPromotionCampaign from '../../components/SelectTenantPromotionCampaign.js';
import {isProduction} from '../../lib/utils.js';

import styles from './DepositGuaranteeApplicationDialog.module.scss';
import ApplicationRejectedByTenantButton from './ApplicationRejectedByTenantButton.js';
import ApplicationAcceptedByProviderButton from './ApplicationAcceptedByProviderButton.js';
import ApplicationRejectedByProviderButton from './ApplicationRejectedByProviderButton.js';
import ReviewDepositGuaranteeApplicationButton from './ReviewDepositGuaranteeApplicationButton.js';
import UploadRentalContractDocumentButton from './UploadRentalContractDocumentButton.js';

export default function DepositGuaranteeApplicationDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [deposit_guarantee_application] = useResource(
    'listDepositGuaranteeApplications',
    id,
  );

  const {is_admin} = useSelector((state) => state.user.account);
  const [readOnly, setReadOnly] = useState(true);
  const {notify} = useNotify();

  const {
    control,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    reset,
    register,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    reset(deposit_guarantee_application);
  }, [reset, deposit_guarantee_application]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      deposit_guarantee_application,
      setError,
      setVisible,
      setReadOnly,
      notify,
    });
  });

  if (!is_admin) {
    alert({
      title: 'Access denied',
      text: 'You need to be admin to access this content.',
    });
    return null;
  }

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  const is_editable =
    is_admin &&
    readOnly &&
    deposit_guarantee_application &&
    deposit_guarantee_application?.review_required;

  return (
    <Dialog
      title="Deposit Guarantee Application"
      show={visible}
      footer={
        <Footer
          deposit_guarantee_application={deposit_guarantee_application}
          readOnly={readOnly}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          isDirty={isDirty}
        />
      }
      additionalIcons={
        deposit_guarantee_application?.id && [is_editable && <EditIcon />]
      }
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className={styles.two_columns}>
        <Input
          value={deposit_guarantee_application?.id || ''}
          label="ID"
          readOnly
        />
        <Input
          value={deposit_guarantee_application?.provider || ''}
          label="Provider"
          readOnly
        />

        <DateInput
          value={deposit_guarantee_application?.created_at}
          label="Created at"
          readOnly
        />
        <DepositReferenceField
          id={deposit_guarantee_application?.deposit_id}
          label="Deposit"
        />
        <Input
          value={`${deposit_guarantee_application.tenant_first_name} ${deposit_guarantee_application.tenant_last_name}`}
          label="Name"
          readOnly
        />
        <Input
          value={deposit_guarantee_application.tenant_email_address || ''}
          label="Email Address"
          readOnly
        />
        <Input
          value={deposit_guarantee_application.phone_number || ''}
          label="Phone Number"
          readOnly
        />
        <Input
          value={
            deposit_guarantee_application.warm_rent_cents
              ? formatPrice(deposit_guarantee_application.warm_rent_cents)
              : '-'
          }
          label="Warm rent cents"
          readOnly
        />
        <Input
          value={
            deposit_guarantee_application.tenant_iban
              ? formatIban(deposit_guarantee_application.tenant_iban)
              : ''
          }
          label="Tenant IBAN"
          readOnly
        />
        <ManagerReferenceField
          id={deposit_guarantee_application?.manager_id}
          label="Manager"
        />
        <PropertyOwnerReferenceField
          id={deposit_guarantee_application?.property_owner_id || ''}
          label="Property owner"
        />
        <Controller
          control={control}
          name="tenant_promotion_campaign_id"
          render={({field: {name, value, onChange}}) => {
            return readOnly ? (
              <TenantPromotionCampaignReferenceField
                id={
                  deposit_guarantee_application?.tenant_promotion_campaign_id ||
                  ''
                }
                label="Tenant promotion campaign"
              />
            ) : (
              <SelectTenantPromotionCampaign
                label="Tenant promotion campaign"
                name={name}
                value={value}
                onChange={onChange}
                readOnly={false}
                error={errors.tenant_promotion_campaign_id?.message}
              />
            );
          }}
        />
      </div>
      <SeparatorLine />

      <JsonField
        value={deposit_guarantee_application.provider_fields || {}}
        name="provider_fields"
        label="Provider Fields"
        collapsed={false}
        displayObjectSize={false}
        displayDataTypes={false}
      />

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={
            deposit_guarantee_application?.monthly_fee_cents
              ? formatPrice(deposit_guarantee_application.monthly_fee_cents)
              : '-'
          }
          label="Monthly fee"
          readOnly
        />
        <Input
          value={
            deposit_guarantee_application?.yearly_fee_cents
              ? formatPrice(deposit_guarantee_application.yearly_fee_cents)
              : '-'
          }
          label="Yearly fee"
          readOnly
        />

        <DateInput
          value={deposit_guarantee_application?.start_date}
          label="Start date"
          readOnly
        />
        <DateInput
          value={deposit_guarantee_application?.accepted_at}
          label="Accepted at"
          readOnly
        />
      </div>

      <SeparatorLine />
      <div className={styles.two_columns}>
        <DateInput
          value={deposit_guarantee_application?.rejected_at}
          label="Rejected at"
          readOnly
        />

        <Input
          value={deposit_guarantee_application?.rejected_by || ''}
          label="Rejected by"
          readOnly
        />

        <Input
          value={deposit_guarantee_application?.reject_reason || ''}
          label="Reject reason"
          readOnly
        />
        <Input
          value={deposit_guarantee_application?.reject_comment || ''}
          label="Reject comment"
          readOnly
        />
      </div>

      <SeparatorLine />
      <div className={styles.link_wrapper}>
        <p className={classNames(text_styles.caption_left, styles.link_label)}>
          Review swk contract
        </p>
        <p className={text_styles.hint}>
          {deposit_guarantee_application?.provider_fields?.swk_contract_url ? (
            <a
              href={
                deposit_guarantee_application?.provider_fields?.swk_contract_url
              }
              rel="noreferrer"
              target="_blank"
              className={text_styles.body2}>
              {deposit_guarantee_application?.provider_fields?.swk_contract_url}
            </a>
          ) : (
            '...'
          )}
        </p>
      </div>

      {deposit_guarantee_application.income_proofs?.length > 0 && (
        <DataGridField
          label="Income Proofs"
          value={deposit_guarantee_application.income_proofs}
          columns={columns_documents}
        />
      )}
      {deposit_guarantee_application.residence_permits?.length > 0 && (
        <DataGridField
          label="Residence Permits"
          value={deposit_guarantee_application.residence_permits}
          columns={columns_documents}
        />
      )}
      {deposit_guarantee_application.city_registrations?.length > 0 && (
        <DataGridField
          label="City Registrations"
          value={deposit_guarantee_application.city_registrations}
          columns={columns_documents}
        />
      )}

      <RentalContractReferenceField
        id={deposit_guarantee_application.rental_contract_id}
        label="Rental Contract"
      />
      <DataGridField
        label="Rental Contract Documents"
        value={deposit_guarantee_application.rental_contract_documents || []}
        columns={columns_rental_contract_documents}
      />

      <JsonField
        value={deposit_guarantee_application.tenant_application || {}}
        name="application"
        label="Application"
      />

      {!isProduction() &&
        deposit_guarantee_application.provider === PROVIDERS.vvrb && (
          <>
            <p className={text_styles.caption_right}>
              {'💻 [test] Choose Schufa Score:'}
            </p>
            <Select
              readOnly={readOnly}
              defaultValue={
                deposit_guarantee_application?.provider_fields
                  ?.mock_schufa_score || 'B'
              }
              options={[
                {id: 'B', name: 'B'},
                {id: 'C', name: 'C'},
                {id: 'D', name: 'D'},
                {id: 'E', name: 'E'},
                {id: 'F', name: 'F'},
                {id: 'G', name: 'G'},
                {id: 'H', name: 'H'},
                {id: 'I', name: 'I'},
                {id: 'J', name: 'J'},
                {id: 'K', name: 'K'},
                {id: 'L', name: 'L'},
                {id: 'M', name: 'M'},
                {id: 'N', name: 'N'},
                {id: 'O', name: 'O'},
                {id: 'P', name: 'P'},
              ]}
              error={errors.mock_schufa_score?.message}
              {...register('mock_schufa_score')}
            />
          </>
        )}
    </Dialog>
  );
}

async function proceed({
  fields,
  deposit_guarantee_application,
  setError,
  setVisible,
  setReadOnly,
  notify,
}) {
  try {
    await updateDepositGuaranteeApplication({
      id: deposit_guarantee_application.id,
      ...fields,
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'The changes have been saved.'});
  setVisible(false);
  setReadOnly(true);
}

const columns_documents = [
  {
    id: 'filename',
    Header: 'Filename',
    accessor: 'filename',
    text_styles: 'body2',
  },
  {
    id: 'url',
    Header: 'Url',
    accessor: 'url',
    text_styles: 'body2',
    Cell: ({value}) => (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    ),
  },
];

const columns_rental_contract_documents = [
  {
    id: 'filename',
    Header: 'Filename',
    accessor: 'filename',
    text_styles: 'body2',
  },
  {
    id: 'url',
    Header: 'Url',
    accessor: 'url',
    text_styles: 'body2',
    Cell: ({value}) => (
      <a href={value} target="_blank" rel="noreferrer">
        {value}
      </a>
    ),
  },
];

function Footer({
  deposit_guarantee_application,
  readOnly,
  onSubmit,
  isSubmitting,
  isDirty,
}) {
  if (!deposit_guarantee_application) return null;

  if (readOnly) {
    return (
      <div>
        <div className={styles.button_row}>
          <ApplicationRejectedByTenantButton
            deposit_guarantee_application={deposit_guarantee_application}
          />
          <ReviewDepositGuaranteeApplicationButton
            deposit_guarantee_application={deposit_guarantee_application}
          />
        </div>
        <div className={styles.button_row}>
          <ApplicationAcceptedByProviderButton
            deposit_guarantee_application={deposit_guarantee_application}
          />
          <ApplicationRejectedByProviderButton
            deposit_guarantee_application={deposit_guarantee_application}
          />
        </div>
        <div className={styles.button_row}>
          <UploadRentalContractDocumentButton
            deposit_guarantee_application_id={deposit_guarantee_application.id}
          />
        </div>
      </div>
    );
  }

  return (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );
}
