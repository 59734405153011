import React from 'react';
import {Modal as BootstrapModal} from 'react-bootstrap';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';

import IconButton from '../IconButton.js';
import Button from '../Button.js';
import {ReactComponent as CloseSvg} from '../../assets/close.svg';

import styles from './OverlayModal.module.scss';

export default function OverlayModal({
  onSubmit,
  onHide,
  title,
  text,
  link,
  ...props
}) {
  function proceed() {
    onHide();
    onSubmit(true);
  }

  function cancel() {
    onHide();
    onSubmit(false);
  }

  return (
    <BootstrapModal
      className={styles.modal_container}
      dialogClassName={styles.modal}
      backdrop="static"
      backdropClassName={styles.transparent_backdrop}
      animation={false}
      {...props}>
      <div className={styles.container}>
        <div className={styles.content}>
          <IconButton className={styles.close} onClick={() => cancel()}>
            <CloseSvg />
          </IconButton>
          <div
            className={classNames(text_styles.body1_bold_left, styles.title)}>
            {title}
          </div>
          <div className={classNames(text_styles.body3, styles.text)}>
            {text} {link}
          </div>
          <div>
            <Button title="OK" onClick={proceed} className={styles.button} />
          </div>
        </div>
      </div>
    </BootstrapModal>
  );
}
