import React, {forwardRef} from 'react';
import classNames from 'classnames/bind.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {ReactComponent as CheckboxEmpty} from './checkbox_empty.svg';
import {ReactComponent as CheckboxChecked} from './checkbox_checked.svg';
import styles from './Checkbox.module.scss';

function Checkbox(
  {id, label, className, textStyle, value, error = '', ...props},
  ref,
) {
  const empty_checkbox = <CheckboxEmpty className={styles.checkbox} />;
  const checked_checkbox = <CheckboxChecked className={styles.checkbox} />;

  return (
    <div className={className}>
      <input
        className={styles.input_checkbox}
        ref={ref}
        id={id}
        type="checkbox"
        checked={value}
        {...props}
      />
      <label
        className={classNames(text_styles.body2_left, textStyle)}
        htmlFor={id}>
        {value ? checked_checkbox : empty_checkbox}
        <span>{label}</span>
      </label>
      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}

export default forwardRef(Checkbox);
