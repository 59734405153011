import {useForm, Controller} from 'react-hook-form';
import DateInput from 'shared-web/components/DateInput';
import Input from 'shared-web/components/Input.js';
import Select from 'shared-web/components/Select.js';
import Checkbox from 'shared-web/components/checkbox/Checkbox.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import PhoneNumberInput from 'shared-web/components/phoneNumberInput/PhoneNumberInput.js';
import {gender_select_options, toLocaleDate} from 'shared/utils.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createManager} from '../../actions.js';

export default function CreateManagerDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );
  const type = watch('type');

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Manager"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <Input
        label="First name"
        {...register('first_name')}
        error={errors.first_name?.message}
      />
      <Input
        label="Last name"
        {...register('last_name')}
        error={errors.last_name?.message}
      />
      <Select
        label="Type"
        options={[
          {id: 'company', name: 'Company'},
          {id: 'individual', name: 'Individual'},
        ]}
        error={errors.type?.message}
        {...register('type')}
      />
      <Input
        label="Company name"
        {...register('company_name')}
        error={errors.company_name?.message}
      />
      <Input
        label="Street name"
        {...register('street_name')}
        error={errors.street_name?.message}
      />
      <Input
        label="Street number"
        {...register('street_number')}
        error={errors.street_number?.message}
      />
      <Input
        label="Postal code"
        {...register('postal_code')}
        error={errors.postal_code?.message}
      />
      <Input
        label="Region"
        {...register('region')}
        error={errors.region?.message}
      />
      <Input
        label="Country"
        readOnly
        defaultValue={'DE'}
        {...register('country')}
        error={errors.country?.message}
      />
      <Input
        label="Email address"
        {...register('email_address')}
        error={errors.email_address?.message}
      />
      <Controller
        name="date_of_birth"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Date of Birth"
            maxDate={new Date()}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
      <Controller
        control={control}
        name="phone_number"
        render={({field: {value, onChange, name}}) => (
          <PhoneNumberInput
            value={value}
            onChange={onChange}
            label="Phone Number"
            error={errors[name]?.message}
          />
        )}
      />
      <Input
        label="Steuernummer (tax number)"
        {...register('tax_id_number')}
        error={errors.tax_id_number?.message}
      />
      {type === 'individual' && (
        <Select
          label="Gender"
          options={gender_select_options}
          error={errors.gender?.message}
          {...register('gender')}
        />
      )}

      <Controller
        control={control}
        name="is_surrogate"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            onChange={onChange}
            label="Surrogate manager"
            error={errors[name]?.message}
          />
        )}
      />

      <Controller
        name="company_founded_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Company Founded Date"
            maxDate={new Date()}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  if (fields.type === 'individual') delete fields.company_name;
  if (fields.type === 'company') delete fields.gender;

  try {
    await createManager({...paramsFromFields(fields)});
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Manager was created.'});
  setVisible(false);
}

function paramsFromFields({date_of_birth, ...fields}) {
  return {
    ...fields,
    date_of_birth: toLocaleDate(date_of_birth),
  };
}
