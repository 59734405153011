import {forwardRef, useEffect, useState} from 'react';
import classNames from 'classnames';
import ReactDatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de/index.js';
import {parseISO} from 'date-fns';
import {l10n, _} from 'shared/l10n.js';
import {toLocaleDate} from 'shared/utils.js';

import text_styles from '../styles/text_styles.module.scss';

import styles from './DateInput.module.scss';

registerLocale('de', de);

// The react-datepicker module has in incorrect default export
// due to interoperability issues between ESM and commonjs
const DatePicker = ReactDatePicker.default;

function DateInput(
  {
    label,
    // TRANSLATORS: This is the date picker placeholder
    placeholder = _('TT.MM.JJJJ'),
    maxDate,
    minDate,
    className,
    error = '',
    value,
    onChange,
    locale = l10n.getLocale(),
    readOnly,
    ...props
  },
  ref,
) {
  const [date, setDate] = useState(parseStringDate(value));

  useEffect(() => {
    setDate(parseStringDate(value));
  }, [value]);

  return (
    <div
      className={classNames(
        readOnly ? styles.read_only_container : styles.container,
        className,
      )}>
      <label className={classNames(text_styles.caption_left, styles.label)}>
        {label}
      </label>
      <DatePicker
        ref={ref}
        className={classNames(text_styles.body2_left, styles.input)}
        locale={locale}
        dateFormat="dd.MM.yyyy"
        maxDate={maxDate}
        minDate={minDate}
        selected={date}
        fixedHeight={true}
        value={date}
        placeholderText={placeholder}
        onChange={(e) => {
          onChange(toLocaleDate(e));
        }}
        readOnly={readOnly}
        {...props}
      />
      {!readOnly && (
        <p className={classNames(text_styles.caption_left, styles.error)}>
          {error}
        </p>
      )}
    </div>
  );
}

function parseStringDate(value) {
  if (typeof value === 'string') {
    return parseISO(value);
  }
  return null;
}

export default forwardRef(DateInput);
