import React from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './LabelWrapper.module.scss';

export function LabelWrapper({label, children}) {
  if (!label) return <>{children}</>;

  return (
    <div className={styles.label_wrapper}>
      <span className={text_styles.caption_left}>{label}</span>
      <div>{children}</div>
    </div>
  );
}
