import {createStore, combineReducers} from 'redux';
import {devToolsEnhancer} from 'redux-devtools-extension';
import modalReducer from 'shared-web/store/modalReducer.js';

import initialState from './initialState.js';
import managersReducer from './managersReducer.js';
import userReducer from './userReducer.js';
import depositGuaranteesReducer from './depositGuaranteesReducer.js';
import depositGuaranteeApplicationsReducer from './depositGuaranteeApplicationsReducer.js';
import claimReducer from './claimReducer.js';
import backofficeUsersReducer from './backofficeUsersReducer.js';
import tenantPromotionCampaignsReducer from './tenantPromotionCampaignsReducer.js';
import rentalContractsReducer from './rentalContractsReducer.js';
import depositAccountsReducer from './depositAccountsReducer.js';

const appReducer = combineReducers({
  modal: modalReducer,
  managers: managersReducer,
  user: userReducer,
  deposit_guarantees: depositGuaranteesReducer,
  deposit_guarantee_applications: depositGuaranteeApplicationsReducer,
  claims: claimReducer,
  deposit_accounts: depositAccountsReducer,
  tenant_promotion_campaigns: tenantPromotionCampaignsReducer,
  backoffice_users: backofficeUsersReducer,
  rental_contracts: rentalContractsReducer,
});

function rootReducer(state, action) {
  if (action.type === 'RESET') {
    state = initialState();
  }
  return appReducer(state, action);
}

export default createStore(rootReducer, initialState(), devToolsEnhancer());
