import React, {useState} from 'react';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {RPC} from 'shared/api';
import Button from 'shared-web/components/Button';

export default function SendInvitationButton({
  deposit_guarantee_application_id,
}) {
  const [loading, setLoading] = useState(false);
  const {notify} = useNotify();

  function onClick() {
    setLoading(true);
    RPC('scheduleTask', [
      'sendTenantInvitation',
      {deposit_guarantee_application_id},
    ])
      .then(() => {
        notify({text: 'Invitation has been sent'});
      })
      .catch((err) => {
        notify({text: err.message || err.code.toString()});
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Button title="Send invitation" onClick={onClick} disabled={loading} />
  );
}
