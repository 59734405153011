import React from 'react';
import classNames from 'classnames';
import {Modal as BootstrapModal} from 'react-bootstrap';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './HintModal.module.scss';
import bedroom_with_lightbulb_image from './bedroom-with-lightbulb.png';

export default function HintModal({
  title,
  text = '',
  email,
  children,
  removeBackdrop,
  image = bedroom_with_lightbulb_image,
  hide_image = false,
  is_wide_modal = false,
  ...props
}) {
  return (
    <BootstrapModal
      className={styles.modal_container}
      dialogClassName={classNames(
        styles.modal,
        is_wide_modal && styles.wide_modal,
      )}
      backdrop="static"
      backdropClassName={removeBackdrop && styles.transparent_backdrop}
      {...props}>
      <div className={styles.content}>
        {(!hide_image && (
          <img
            src={image}
            alt=""
            role="presentation"
            className={styles.modal_img}
            height="261px"
            width="400px"
          />
        )) || <></>}
        {title && (
          <div
            className={classNames(
              text_styles.body1_bold_centered,
              styles.title,
            )}>
            {title.trim()}
          </div>
        )}
        {text && (
          <div className={classNames(text_styles.body2_centered, styles.text)}>
            {text}
          </div>
        )}
        {email && (
          <a
            href={`mailto:${email}`}
            className={classNames(text_styles.body2_centered, styles.email)}>
            {email}
          </a>
        )}
        {children}
      </div>
    </BootstrapModal>
  );
}
