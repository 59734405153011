/* eslint-env browser */

import {_} from 'shared/l10n.js';
import {VALIDATION_MESSAGE} from 'shared/constants.js';
import {UNAUTHORIZED} from 'shared/api.js';

export function setFormErrors({setError, errors}) {
  for (const {path, message} of errors) {
    const input_name = path.slice(1).replaceAll('/', '.');
    const validation_message = VALIDATION_MESSAGE[message];
    setError(input_name, {
      message: validation_message ? _(validation_message) : message,
    });
  }
}

export function scrollToError(errors) {
  if (errors.length > 0);
  const input_name = errors[0].path.slice(1).replaceAll('/', '.');
  let first_error_element = window.document.getElementsByName(input_name)[0];
  if (!first_error_element) return;

  const style_display_none =
    window.getComputedStyle(first_error_element).display === 'none';
  const style_visibility_hidden =
    window.getComputedStyle(first_error_element).visibility === 'hidden';

  if (style_display_none || style_visibility_hidden) {
    first_error_element = first_error_element.labels?.[0];
  }

  first_error_element?.scrollIntoView({behavior: `smooth`, block: 'center'});
}

export function initializeEffects(store) {
  function alert(props) {
    return new Promise((onSubmit) => {
      store.dispatch({
        type: 'SHOW_MODAL',
        name: 'ALERT',
        props: {
          ...props,
          onSubmit,
        },
      });
    });
  }

  function confirm(props) {
    return new Promise((onSubmit) => {
      store.dispatch({
        type: 'SHOW_MODAL',
        name: 'CONFIRM',
        props: {
          ...props,
          onSubmit,
        },
      });
    });
  }

  function overlayConfirm(props) {
    return new Promise((onSubmit) => {
      store.dispatch({
        type: 'SHOW_MODAL',
        name: 'OVERLAY',
        props: {
          ...props,
          onSubmit,
        },
      });
    });
  }

  // eslint-disable-next-line unicorn/consistent-function-scoping
  function reportError(err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  function showGenericError() {
    alert({
      title: _('Da ist etwas schiefgegangen, es tut uns leid'),
      text: _(
        'Wir versuchen das Problem schnellstmöglich zu beheben. Bitte versuchen Sie es nach einem kurzen Moment erneut.',
      ),
    });
  }

  function handleError(err) {
    reportError(err);

    if (err.code === UNAUTHORIZED) {
      alert({
        title: _('Sorry, da ist was schiefgelaufen'),
        text: _('Du wurdest abgemeldet, bitte melde dich erneut an'),
        backdrop: 'static',
        label: _('Zum Login'),
      }).then(() => {
        document.location.pathname = '/';
      });
      return;
    }

    showGenericError();
  }

  function closeModal() {
    store.dispatch({type: 'HIDE_MODAL'});
  }

  function onDialogClosed() {
    store.dispatch({type: 'MODAL_CLOSED'});
  }

  return {
    alert,
    confirm,
    handleError,
    reportError,
    showGenericError,
    closeModal,
    onDialogClosed,
    overlayConfirm,
  };
}

export function copyText(name, text, notify) {
  navigator.clipboard.writeText(text);
  notify({
    text: `${name} kopiert`,
  });
}
