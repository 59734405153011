import React, {forwardRef, useCallback, useState, useEffect} from 'react';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {_} from 'shared/l10n.js';

import text_input_styles from './TextInput.module.scss';
import styles from './TextArea.module.scss';

function TextArea(
  {
    label,
    placeholder = '...',
    inputClassName,
    className,
    error = '',
    maxLength,
    onChange,
    multiline = true,
    isBright = false,
    readOnly,
    ...props
  },
  ref,
) {
  const [length, setLength] = useState(0);

  useEffect(
    () => setLength(props?.defaultValue?.length || 0),
    [props?.defaultValue],
  );

  const onTextAreaChange = useCallback(
    (evt) => {
      setLength(evt.target.value.length);
      onChange(evt);
    },
    [onChange],
  );

  return (
    <div
      className={classNames(
        readOnly ? styles.read_only_container : styles.container,
        className,
      )}>
      {label && (
        <div className={classNames(text_styles.caption_left, styles.label)}>
          {label}
        </div>
      )}
      {multiline ? (
        <textarea
          className={classNames(styles.text_area, inputClassName)}
          ref={ref}
          placeholder={placeholder}
          onChange={onTextAreaChange}
          maxLength={maxLength}
          readOnly={readOnly}
          {...props}
        />
      ) : (
        <input
          type="text"
          ref={ref}
          onChange={onTextAreaChange}
          className={text_input_styles.input}
          maxLength={maxLength}
          readOnly={readOnly}
          {...props}
        />
      )}

      <div className={styles.error_container}>
        {!readOnly && (
          <p className={classNames(text_styles.caption_left, styles.error)}>
            {error}
          </p>
        )}

        {!!maxLength && (
          <div
            className={
              isBright
                ? text_styles.caption_bright_right
                : text_styles.caption_right
            }>
            {_('%1 / %2 Zeichen', length, maxLength)}
          </div>
        )}
      </div>
    </div>
  );
}

export default forwardRef(TextArea);
