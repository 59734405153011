import React from 'react';
import {_} from 'shared/l10n.js';

import Button from '../Button.js';

import styles from './AlertModal.module.scss';
import HintModal from './HintModal.js';

export default function AlertModal({
  label = _('OK'),
  onHide,
  onSubmit,
  ...props
}) {
  function proceed() {
    onHide();
    onSubmit();
  }

  return (
    <HintModal {...props}>
      <div className={styles.button}>
        {label && <Button title={label} onClick={proceed} />}
      </div>
    </HintModal>
  );
}
