import {useState, useCallback, useEffect, useRef, useMemo} from 'react';
import {useCombobox} from 'downshift';
import {useDebouncedCallback} from 'use-debounce';
import classNames from 'classnames';
import {RPC} from 'shared/api.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import IconButton from '../IconButton.js';

import {ReactComponent as ChevronDownSvg} from './chevron_down.svg';
import {ReactComponent as DeleteSvg} from './icon-delete-text.svg';
import styles from './Combobox.module.scss';

export default function Combobox({
  label,
  value,
  onChange,
  selectedItemListener,
  readOnly,
  error = '',
  placeholder,
  DropdownItem,
  itemToString,
  NoResultsPlaceholder,
  NoSearchResultsPlaceholder,
  button_new,
  name,
  rpc_method,
  rpcParamsFromComboboxData,
  scrollable_search_result = false,
}) {
  const inputRef = useRef(null);
  const [results, setResults] = useState(null);

  const [selected_item, setSelectedItem] = useState({});
  const items = useMemo(() => results || [], [results]);

  useEffect(() => {
    setSelectedItem((selected_item) => {
      const new_selected_item = items.find((item) => item.id === value);
      if (selected_item?.id !== new_selected_item?.id) {
        return new_selected_item || {};
      }
      return selected_item || new_selected_item || {};
    });
  }, [items, value]);

  const onSelectedItemChange = useCallback(
    ({selectedItem}) => {
      if (selectedItem === null) {
        onChange(null);
        selectedItemListener?.(null);
        return;
      }
      onChange(selectedItem?.id);
      selectedItemListener?.(selectedItem);
    },
    [onChange, selectedItemListener],
  );

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    inputValue,
    reset,
    setInputValue,
  } = useCombobox({
    items,
    selectedItem: selected_item,
    onSelectedItemChange,
    itemToString,
  });

  const fetchData = useCallback(() => {
    const rpc_params = {};
    if (!scrollable_search_result || inputValue.length === 0) {
      rpc_params.pagination = [0, 3];
    } else {
      delete rpc_params.pagination;
    }

    RPC(rpc_method, {
      ...rpcParamsFromComboboxData({value, input_value: inputValue}),
      ...rpc_params,
    })
      .then(({rows}) => {
        setResults(rows);
      })
      .catch();
  }, [
    rpc_method,
    rpcParamsFromComboboxData,
    value,
    inputValue,
    scrollable_search_result,
  ]);

  const debouncedFetchData = useDebouncedCallback(() => {
    fetchData();
  }, 500);

  /*
   * Deliberately not running the effect when fetchData changes, since it depends on inputValue.
   * We want to react to changes to inputValue with a debounce, see next effect
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchData, [
    rpc_method,
    rpcParamsFromComboboxData,
    scrollable_search_result,
    value,
  ]);

  useEffect(debouncedFetchData, [debouncedFetchData, inputValue]);

  const onFocus = useCallback(() => {
    if (!isOpen && !readOnly) {
      openMenu();
    }
  }, [isOpen, openMenu, readOnly]);

  const handleInputBlur = useCallback(() => {
    const selected = items.find((item) => item.id === value);
    if (!selected) return;
    setInputValue(itemToString(selected));
  }, [items, value, setInputValue, itemToString]);

  const handleReset = useCallback(
    (event) => {
      if (event.defaultPrevented) return;
      event.preventDefault();
      reset();
      onChange(null);
      selectedItemListener?.(null);
      openMenu();
      inputRef.current?.focus();
    },
    [reset, onChange, selectedItemListener, openMenu],
  );

  let no_results_message;
  if (!inputValue && items && items.length === 0 && NoResultsPlaceholder) {
    no_results_message = <NoResultsPlaceholder />;
  } else if (!items || items.length === 0) {
    no_results_message = (
      <NoSearchResultsPlaceholder search_query={inputValue} />
    );
  }

  return (
    <div className={styles.container}>
      {label && (
        <label
          className={classNames(text_styles.caption_left, styles.label)}
          {...getLabelProps()}>
          {label}
        </label>
      )}

      <div
        className={classNames(styles.toggle, readOnly && styles.read_only)}
        {...getComboboxProps()}>
        <input
          {...getInputProps({
            onFocus,
            onBlur: handleInputBlur,
            className: classNames(text_styles.body2_left, styles.input),
            placeholder,
            readOnly,
            name,
            ref: inputRef,
          })}
        />
        {!readOnly && (
          <div className={styles.buttons}>
            {isOpen && inputValue.length > 0 && (
              <IconButton onClick={handleReset} className={styles.delete}>
                <DeleteSvg />
              </IconButton>
            )}
            <IconButton {...getToggleButtonProps()}>
              <ChevronDownSvg />
            </IconButton>
          </div>
        )}
      </div>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul className={styles.dropdown}>
            <div
              className={classNames(
                styles.menu_item,
                scrollable_search_result &&
                  inputValue.length > 0 &&
                  styles.scrollable,
              )}>
              {items?.map((item, index) => (
                <li
                  className={styles.li}
                  key={item.id}
                  {...getItemProps({item, index})}>
                  <DropdownItem item={item} />
                </li>
              ))}
              {no_results_message}
            </div>
            {button_new && <div onClick={reset}>{button_new}</div>}
          </ul>
        )}
      </div>
      <p className={classNames(text_styles.caption_left, styles.error)}>
        {error}
      </p>
    </div>
  );
}
